import React from 'react';
import { ChevronRight, CloudLightning, Globe, Brain, TrendingUp, Mail, Phone, MapPin } from 'lucide-react';
import MapComponent from './MapComponent'; // Import the MapComponent

const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={`px-4 py-2 rounded font-semibold text-white transition-colors ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header */}
      <header className="bg-gray-800 py-4">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold">lvrgd.ai</h1>
        </div>
      </header>

      

      {/* Hero Section */}
      <section className="py-20 bg-gradient-to-b from-gray-800 to-gray-900">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl sm:text-5xl font-bold mb-4">AI-Powered Prop Trading</h2>
          <p className="text-lg sm:text-xl mb-8">Leveraging AWS AI services for global asset trading</p>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h3 className="text-3xl font-semibold mb-8 text-center">Our Capabilities</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard 
              icon={<Brain className="text-blue-400" size={40} />}
              title="AI & Machine Learning"
              description="Cutting-edge algorithms for predictive trading"
              details={[
                "Natural Language Processing for sentiment analysis",
                "Deep Learning models for price prediction",
                "Reinforcement Learning for optimal trade execution"
              ]}
            />
            <FeatureCard 
              icon={<Globe className="text-green-400" size={40} />}
              title="Global Assets"
              description="Trading in diverse markets worldwide"
              details={[
                "Global futures contracts",
                "Options on major indices and commodities",
                "Forex pairs across all major currencies",
                "Cryptocurrency markets including Bitcoin, Ethereum, and more"
              ]}
            />
            <FeatureCard 
              icon={<CloudLightning className="text-purple-400" size={40} />}
              title="AWS-Powered Infrastructure"
              description="Leveraging AWS for scalable and reliable operations"
              details={[
                "Amazon Bedrock for foundation models and generative AI",
                "Amazon SageMaker for ML model development and deployment",
                "AWS Lambda for serverless compute",
                "Amazon S3 for data storage and retrieval"
              ]}
            />
            <FeatureCard 
              icon={<TrendingUp className="text-red-400" size={40} />}
              title="Proprietary Strategies"
              description="Unique approaches to maximize returns"
              details={[
                "High-frequency trading algorithms",
                "Statistical arbitrage models",
                "Long/short equity strategies",
                "Volatility trading techniques"
              ]}
            />
          </div>
        </div>
      </section>

      {/* Contact and Location Section */}
      <section className="py-16 bg-gray-800">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h3 className="text-3xl font-semibold mb-8 text-center">Contact Us</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h4 className="text-xl font-semibold mb-4">Location</h4>
              <p className="flex items-center mb-2">
                <MapPin className="mr-2 flex-shrink-0" size={20} />
                <span className="break-words">395 SW Bluff Dr, Suite 10, Bend OR 97702</span>
              </p>
              <div className="h-64 bg-gray-700 rounded-lg mt-4">
                <MapComponent /> {/* Include the MapComponent */}
              </div>
            </div>
            <div>
              <h4 className="text-xl font-semibold mb-4">Get in Touch</h4>
              <p className="flex items-center mb-2">
                <Mail className="mr-2 flex-shrink-0" size={20} />
                <a href="mailto:brandon@lvrgd.ai" className="hover:text-blue-400">brandon@lvrgd.ai</a>
              </p>
              <p className="flex items-center mb-2">
                <Phone className="mr-2 flex-shrink-0" size={20} />
                <a href="tel:+15413954500" className="hover:text-blue-400">(541) 797-8409</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p>&copy; 2024 lvrgd.ai. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description, details }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <div className="flex justify-center mb-4">{icon}</div>
      <h4 className="text-xl font-semibold mb-2">{title}</h4>
      <p className="text-gray-400 mb-4">{description}</p>
      <ul className="list-disc list-inside text-gray-300">
        {details.map((detail, index) => (
          <li key={index} className="mb-1">{detail}</li>
        ))}
      </ul>
    </div>
  );
};

export default HomePage;